import { User, UserAuthorizationClaim } from "@leadgen/models";
import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { UserSessionStorage } from "../../interfaces/user-session";

export interface SetSessionActionProps {
  session: Partial<UserSessionStorage> | null;
  keepLoggedIn: boolean;
}

export interface SetUserAuthorizationActionProps {
  authorization: UserAuthorizationClaim;
}

export interface SetSessionUserActionProps {
  user: User;
}

export const AuthSessionActions = createActionGroup({
  source: "Auth/Session",
  events: {
    setSessionUser: props<SetSessionUserActionProps>(),
    setSession: props<SetSessionActionProps>(),
    setUserAuthorization: props<SetUserAuthorizationActionProps>(),
    removeUserSession: emptyProps(),
  },
});
