import { UnknownError } from "@leadgen/errors";
import { Coerce } from "@eqn/data-types";

export class AccountAlreadyExistsError {
  constructor(public readonly message: string) {}
}

export class AccountRegisterValidationError {
  public readonly message: string;

  constructor(message: string | string[]) {
    this.message = Coerce.array(message)[0];
  }
}

export type AccountRegisterRequestErrors =
  | AccountAlreadyExistsError
  | AccountRegisterValidationError
  | UnknownError;
