import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map } from "rxjs";
import { EmailVerificationPageActions } from "./actions/verification-page.actions";
import { AuthApiActions } from "../../auth/state/actions/auth-api.actions";

@Injectable()
export class VerificationEffects {
  private readonly _onRegistrationSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthApiActions.registerSuccess),
      map(() =>
        EmailVerificationPageActions.setAfterRegistrationState({ isRegistrationCompleted: true })
      )
    )
  );

  constructor(private readonly _actions$: Actions) {}
}
