import { HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { first, mergeMap } from "rxjs/operators";
import { selectAccessToken } from "../auth/state";
import { AuthSessionState } from "../auth/state/auth.state";
import { getAccessToken } from "./access-token";

export function AuthorizationInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn) {
  return (
    inject(Store<AuthSessionState>)
      // select user access token
      .select(selectAccessToken)
      .pipe(
        // get the access token one time (do not watch for access token changes)
        first(),
        // if access token is present in the session
        // filter(ValidationUtil.isNotEmpty),
        // map and merge all requests with `authorization` header
        mergeMap((accessToken) => {
          accessToken = getAccessToken();

          // const alreadyHasAuthToken = request.headers.get("Authorization");

          // if request does not have token attach
          // if (!alreadyHasAuthToken && accessToken) {
          // set the `authorization` header with token

          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          // }

          return next(request);
        })
      )
  );
}
