import { Component, inject } from "@angular/core";
import { CoreService } from "../../services/core.service";
import { RouterModule } from "@angular/router";
import { MatSidenavModule } from "@angular/material/sidenav";
import { NgClass } from "@angular/common";
import { AppSettings, ThemeColorScheme } from "../../app.config";

@Component({
  selector: "app-blank",
  standalone: true,
  imports: [RouterModule, MatSidenavModule, NgClass],
  templateUrl: "./blank.component.html",
  styleUrls: [],
})
export class BlankLayoutComponent {
  private readonly _htmlElement!: HTMLHtmlElement;
  public THEME_COLOR = ThemeColorScheme;

  public themeOptions = inject(CoreService).getOptions();

  constructor() {
    this._htmlElement = document.querySelector("html")!;
    // Initialize project theme with options
    this._receiveOptions(this.themeOptions);
  }

  private _receiveOptions(options: AppSettings): void {
    this.themeOptions = options;
    this._toggleDarkTheme(options);
  }

  private _toggleDarkTheme(options: AppSettings) {
    if (options.theme === "dark") {
      this._htmlElement.classList.add("dark-theme");
      this._htmlElement.classList.remove("light-theme");
    } else {
      this._htmlElement.classList.remove("dark-theme");
      this._htmlElement.classList.add("light-theme");
    }
  }
}
