import { TaskState } from "./task-state.constants";

export class Task {
  static isUnderProgress(state: TaskState) {
    return (
      state === TaskState.PROGRESSING || state === TaskState.LOADING || state === TaskState.WAITING
    );
  }

  static isCompleted(state: TaskState) {
    return state === TaskState.COMPLETED || state === TaskState.ERROR;
  }

  static isIdel(state: TaskState) {
    return Task.isCompleted(state) || state === TaskState.IDLE;
  }

  static isLoading(state: TaskState) {
    return Task.isUnderProgress(state);
  }

  static hasError(state: TaskState) {
    return state === TaskState.ERROR;
  }

  static isStarted(state: TaskState, checkLoadingState = false) {
    const isTaskStated = state == TaskState.STARTING || state === TaskState.STARTED;

    if (checkLoadingState) {
      return isTaskStated || Task.isUnderProgress(state);
    }

    return isTaskStated;
  }
}
