import { HttpClient, HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, map, throwError } from "rxjs";
import { RegistrationResponse, UserRegistrationParams } from "../interfaces/user-registration";

import { AppPlatform, EMPTY_REQ_BODY, HttpUtil, IHttpResponse } from "@eqn/common";
import {
  AccessForbiddenError,
  EmailNotFoundError,
  UnauthorizedError,
  UnknownError,
  UserNotFoundError,
} from "@leadgen/errors";
import { UserAuthorizationClaim } from "@leadgen/models";
import { environment } from "../../../environments/environment";
import {
  AccountAlreadyExistsError,
  AccountRegisterRequestErrors,
  AccountRegisterValidationError,
} from "../errors/account-register-errors";
import { UserAuthorizationRequestErrors } from "../errors/authorization-errors";
import { ForgotPasswordRequestErrors } from "../errors/forgot-pwd-errors";
import {
  ResetPasswordRequestErrors,
  ResetPasswordTokenValidationRequestErrors,
  ResetPasswordValidationError,
} from "../errors/reset-pwd-errors";
import { Credentials, LoginResponse } from "../interfaces/user-login";
import { IAccountQuota } from "../../account/quotas/interface/quota.interface";
import { ObservableUtil } from "@eqn/rxjs";

function userApiAuthorizationErrorHandler(
  httpError: HttpErrorResponse
): Observable<UserAuthorizationRequestErrors> {
  const error = httpError?.error;
  const statusCode = httpError?.status;
  const message = error?.message || httpError?.message;

  let exception: UserAuthorizationRequestErrors;

  switch (statusCode) {
    case HttpStatusCode.NotFound:
      exception = new UserNotFoundError(message);
      break;

    case HttpStatusCode.Unauthorized:
      exception = new UnauthorizedError(message);
      break;

    case HttpStatusCode.Forbidden:
      exception = new AccessForbiddenError(error);
      break;

    default:
      exception = new UnknownError(error);
  }

  return throwError(() => exception);
}

@Injectable({
  providedIn: "root",
})
export class AuthApiService {
  private readonly _authApiEndpoint = `${environment.apiBaseUrl}/auth`;

  constructor(private readonly _http: HttpClient) {}

  public getUserPermissions(): Observable<UserAuthorizationClaim | UserAuthorizationRequestErrors> {
    const url = `${this._authApiEndpoint}/permissions`;

    return this._http
      .get<UserAuthorizationClaim>(url)
      .pipe(catchError(userApiAuthorizationErrorHandler));
  }

  public getUserPermissionsAsync<T>() {
    const url = `${this._authApiEndpoint}/permissions`;

    return HttpUtil.getResponse<T>(
      ObservableUtil.toPromised<IHttpResponse<T>>(
        this._http.get<T>(url).pipe(catchError(userApiAuthorizationErrorHandler))
      ),
      "",
      false
    );
  }

  public register(
    userRegistrationParams: UserRegistrationParams
  ): Observable<RegistrationResponse | AccountRegisterRequestErrors> {
    const registrationErrorsHandler = (
      httpError: HttpErrorResponse
    ): Observable<AccountRegisterRequestErrors> => {
      const error = httpError?.error;
      const statusCode = httpError?.status;
      const message = error?.message || httpError?.message;

      let exception: AccountRegisterRequestErrors;

      switch (statusCode) {
        case HttpStatusCode.Conflict:
          exception = new AccountAlreadyExistsError(message);
          break;

        case HttpStatusCode.BadRequest:
          exception = new AccountRegisterValidationError(message);
          break;

        default:
          exception = new UnknownError(error);
      }

      return throwError(() => exception);
    };

    const url = `${this._authApiEndpoint}/register`;

    const registerRequest = { ...userRegistrationParams, platform: AppPlatform.WEB_APP };

    return this._http
      .post<RegistrationResponse>(url, registerRequest)
      .pipe(catchError(registrationErrorsHandler));
  }

  public login(
    credentials: Credentials
  ): Observable<LoginResponse | UserAuthorizationRequestErrors> {
    const url = `${this._authApiEndpoint}/login`;

    const loginRequest = { ...credentials, platform: AppPlatform.WEB_APP };

    return this._http
      .post<LoginResponse>(url, loginRequest)
      .pipe(catchError(userApiAuthorizationErrorHandler));
  }

  public logout(): Observable<void | UserAuthorizationRequestErrors> {
    const url = `${this._authApiEndpoint}/logout`;

    return this._http
      .post<void>(url, EMPTY_REQ_BODY)
      .pipe(catchError(userApiAuthorizationErrorHandler));
  }

  public requestPasswordReset(email: string): Observable<void | ForgotPasswordRequestErrors> {
    const reqPwdResetErrorsHandler = (
      httpError: HttpErrorResponse
    ): Observable<ForgotPasswordRequestErrors> => {
      const error = httpError?.error;
      const statusCode = httpError?.status;

      let exception: ForgotPasswordRequestErrors;

      switch (statusCode) {
        case HttpStatusCode.NotFound:
          exception = new EmailNotFoundError(email);
          break;

        default:
          exception = new UnknownError(error);
      }

      return throwError(() => exception);
    };

    const url = `${this._authApiEndpoint}/forgot-password`;

    return this._http.post<void>(url, { email }).pipe(catchError(reqPwdResetErrorsHandler));
  }

  public validatePasswordResetToken(
    resetPwdToken: string
  ): Observable<void | ResetPasswordTokenValidationRequestErrors> {
    const resetPwdTokenValidationErrorsHandler = (
      httpError: HttpErrorResponse
    ): Observable<ResetPasswordTokenValidationRequestErrors> => {
      const error = httpError?.error;
      const statusCode = httpError?.status;
      const message = error?.message || httpError?.message;

      let exception: ResetPasswordTokenValidationRequestErrors;

      switch (statusCode) {
        case HttpStatusCode.Unauthorized:
        case HttpStatusCode.Gone:
          exception = new UnauthorizedError(message);
          break;

        default:
          exception = new UnknownError(error);
      }

      return throwError(() => exception);
    };

    const url = `${this._authApiEndpoint}/reset-password/validate`;

    const authorizationHeader = {
      Authorization: `Bearer ${resetPwdToken}`,
    };

    return this._http
      .get<void>(url, {
        headers: authorizationHeader,
      })
      .pipe(catchError(resetPwdTokenValidationErrorsHandler));
  }

  public resetPassword(newPassword: string): Observable<void | ResetPasswordRequestErrors> {
    const resetPwdErrorsHandler = (
      httpError: HttpErrorResponse
    ): Observable<ResetPasswordRequestErrors> => {
      const error = httpError?.error;
      const statusCode = httpError?.status;
      const message = error?.message || httpError?.message;

      let exception: ResetPasswordRequestErrors;

      switch (statusCode) {
        case HttpStatusCode.Unauthorized:
        case HttpStatusCode.Gone:
          exception = new UnauthorizedError(message);
          break;

        case HttpStatusCode.BadRequest:
          exception = new ResetPasswordValidationError(message);
          break;

        default:
          exception = new UnknownError(error);
      }

      return throwError(() => exception);
    };

    const url = `${this._authApiEndpoint}/reset-password`;

    return this._http
      .post<void>(url, { password: newPassword })
      .pipe(catchError(resetPwdErrorsHandler));
  }
}
