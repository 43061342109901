import { ChangeDetectionStrategy, Component, inject, ViewEncapsulation } from "@angular/core";
import { NgIf } from "@angular/common";
import { CoreService } from "../services/core.service";

@Component({
  selector: "emailstak-web-logo",
  standalone: true,
  imports: [NgIf],
  template: ` <div class="text-center">
    <a *ngIf="theme === 'light'">
      <img alt="logo" class="align-middle m-2" src="./assets/images/logos/dark-logo.svg" />
    </a>
    <a *ngIf="theme === 'dark'">
      <img alt="logo" class="align-middle m-2" src="./assets/images/logos/light-logo.svg" />
    </a>
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AppLogoComponent {
  public readonly theme = inject(CoreService).getOptions()?.theme;
}
