import { provideStoreDevtools } from "@ngrx/store-devtools";
import { EnvironmentProviders } from "@angular/core";

export interface AppEnvironment {
  production: boolean;
  localStorageStoreName: string;
  apiBaseUrl: string;
  gqlEndpoint: string;
  providers: EnvironmentProviders[];
}

export const environment: AppEnvironment = {
  production: false,
  localStorageStoreName: window.location.host,
  apiBaseUrl: "https://dev-api.emailstak.com",
  gqlEndpoint: "https://dev-graph.emailstak.com",
  providers: [
    provideStoreDevtools({
      maxAge: 25, // Retains last 25 states
      logOnly: false, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
      connectOutsideZone: true, // If set to true, the connection is established outside the Angular zone for better performance
    }),
  ],
};
