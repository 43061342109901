import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class AppService {
  private readonly _logoutRequestSubject = new Subject<void>();

  public readonly logoutRequested$ = this._logoutRequestSubject.asObservable();

  public requestLogout() {
    this._logoutRequestSubject.next();
  }
}
