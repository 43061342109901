import { BreakpointObserver, MediaMatcher } from "@angular/cdk/layout";
import {
  Component,
  inject,
  Injectable,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Subscription } from "rxjs";
import { MatSidenav, MatSidenavContent } from "@angular/material/sidenav";
import { AppSettings, CoreService, ThemeColorScheme } from "@modernize";
import { filter } from "rxjs/operators";
import { NavigationEnd, Router } from "@angular/router";

const MOBILE_VIEW = "screen and (max-width: 768px)";
const TABLET_VIEW = "screen and (min-width: 769px) and (max-width: 1024px)";
const MONITOR_VIEW = "screen and (min-width: 1024px)";
const BELOWMONITOR = "screen and (max-width: 1023px)";

@Injectable()
export class AppSidenavService {
  public sidenav: MatSidenav;
}

@Component({
  selector: "leadgen-base-layout",
  standalone: true,
  template: ``,
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
})
export abstract class BaseAppLayoutComponent implements OnDestroy {
  @ViewChild("content", { static: true })
  public content!: MatSidenavContent;

  public resView = false;
  public isCustomizerEnabled = false;
  public sideNavClasses = {};
  protected readonly sidenavService = inject(AppSidenavService);
  private readonly _settings = inject(CoreService);

  //get options from service
  public themeOptions = this._settings.getOptions();
  private readonly _mediaMatcher = inject(MediaMatcher);
  private readonly _router = inject(Router);
  private readonly _breakpointObserver = inject(BreakpointObserver);
  private _layoutChangesSubscription = Subscription.EMPTY;
  private _isMobileScreen = false;
  private _isContentWidthFixed = true;
  private _isCollapsedWidthFixed = false;
  private _htmlElement!: HTMLHtmlElement;

  protected constructor() {
    this.sideNavClasses = {
      aqua_theme: this.themeOptions.activeTheme == ThemeColorScheme.AQUA,
      blue_theme: this.themeOptions.activeTheme == ThemeColorScheme.BLUE,
      cardBorder: this.themeOptions.cardBorder,
      cyan_theme: this.themeOptions.activeTheme == ThemeColorScheme.CYAN,
      green_theme: this.themeOptions.activeTheme == ThemeColorScheme.GREEN,
      orange_theme: this.themeOptions.activeTheme == ThemeColorScheme.ORANGE,
      purple_theme: this.themeOptions.activeTheme == ThemeColorScheme.PURPLE,
      "sidebarNav-horizontal": this.themeOptions.horizontal,
      "sidebarNav-mini": this.themeOptions.sidenavCollapsed && this.themeOptions.navPos !== "top",
    };

    this._htmlElement = document.querySelector("html")!;
    this._layoutChangesSubscription = this._breakpointObserver
      .observe([MOBILE_VIEW, TABLET_VIEW, MONITOR_VIEW, BELOWMONITOR])
      .subscribe((state: { breakpoints: { [x: string]: boolean } }) => {
        // SidenavOpened must be reset true when layout changes
        this.themeOptions.sidenavOpened = true;
        this._isMobileScreen = state.breakpoints[MOBILE_VIEW];
        if (this.themeOptions.sidenavCollapsed == false) {
          this.themeOptions.sidenavCollapsed = state.breakpoints[TABLET_VIEW];
        }
        this._isContentWidthFixed = state.breakpoints[MONITOR_VIEW];
        this.resView = state.breakpoints[BELOWMONITOR];
      });

    // Initialize project theme with options
    this._receiveOptions(this.themeOptions);

    // This is for scroll to top
    this._router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((e) => {
      (this.content as any)?.scrollTo({ top: 0 });
    });
  }

  get sidenav() {
    return this.sidenavService.sidenav;
  }

  set sidenav(sidenav: MatSidenav) {
    this.sidenavService.sidenav = sidenav;
  }

  get isOver(): boolean {
    return this._isMobileScreen;
  }

  get isTablet(): boolean {
    return this.resView;
  }

  ngOnDestroy() {
    this._layoutChangesSubscription.unsubscribe();
  }

  public toggleSidenav() {
    if (!this.sidenavService.sidenav) {
      return;
    }

    this.sidenavService.sidenav.toggle().then(() => {
      this.content.scrollTo({ top: 0 });
    });
  }

  public toggleCollapsed() {
    this._isContentWidthFixed = false;
    this.themeOptions.sidenavCollapsed = !this.themeOptions.sidenavCollapsed;
    this.resetCollapsedState();
  }

  public resetCollapsedState(timer = 400) {
    setTimeout(() => this._settings.setOptions(this.themeOptions), timer);
  }

  public onSidenavClosedStart() {
    this._isContentWidthFixed = false;
  }

  public onSidenavOpenedChange(isOpened: boolean) {
    this._isCollapsedWidthFixed = !this.isOver;
    this.themeOptions.sidenavOpened = isOpened;
    this._settings.setOptions(this.themeOptions);
  }

  public toggleDarkTheme(options: AppSettings) {
    if (options.theme === "dark") {
      this._htmlElement.classList.add("dark-theme");
      this._htmlElement.classList.remove("light-theme");
    } else {
      this._htmlElement.classList.remove("dark-theme");
      this._htmlElement.classList.add("light-theme");
    }
  }

  private _receiveOptions(options: AppSettings): void {
    this.themeOptions = options;
    this.toggleDarkTheme(options);
    this.isCustomizerEnabled = options.customizer;
  }
}
