export const AppResourceSubject = {
  Dashboard: "Dashboard",
  Account: "Account",
  Subscription: "Subscription",
  Invite: "Invite",
  Download: "Download",
  all: "all",
} as const;

export type AppResource = keyof typeof AppResourceSubject;
