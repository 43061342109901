import { AfterViewInit, Component, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatSidenav, MatSidenavModule } from "@angular/material/sidenav";
import { RouterModule } from "@angular/router";

import { NgClass, NgFor } from "@angular/common";
import { NgScrollbarModule } from "ngx-scrollbar";
import { MatListModule } from "@angular/material/list";
import { AppNavItemComponent, NavItem } from "../app/vertical/sidebar/nav-item";
import { BaseAppLayoutComponent } from "../app/base-layout.component";

@Component({
  selector: "leadgen-sendmail-layout",
  standalone: true,
  imports: [
    RouterModule,
    NgClass,
    AppNavItemComponent,
    MatSidenavModule,
    MatListModule,
    NgScrollbarModule,
    NgFor,
  ],
  templateUrl: "./layout.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class SendEmailsDashboardLayoutComponent
  extends BaseAppLayoutComponent
  implements AfterViewInit
{
  @ViewChild("sendEmailsSidenav") public sendEmailsSidenav: MatSidenav;

  public readonly NAV_ITEMS: NavItem[] = [
    { navCap: "Manage" },
    { displayName: "Email Accounts", route: "/email-warmup", iconName: "mail" },
    {
      displayName: "Campaigns",
      route: "campaigns",
      iconName: "crown",
    },
    { displayName: "Inboxes", route: "/unibox", iconName: "messages" },
    { displayName: "Analytics", route: "/analytics", iconName: "chart-line" },
  ];

  constructor() {
    super();
  }

  ngAfterViewInit() {
    this.sidenav = this.sendEmailsSidenav;
  }
}
