export type Optional<T, K extends keyof T> = Partial<Pick<T, K>> & Omit<T, K>;

export type PickAndRequired<T, Props extends keyof T, RequiredProps extends keyof T = Props> = Pick<
  T,
  Props
> &
  Required<Pick<T, RequiredProps>>;

export type PickAndReadonly<T, Props extends keyof T, ReadonlyProps extends keyof T = Props> = Pick<
  T,
  Props
> &
  Readonly<Pick<T, ReadonlyProps>>;

export type PartialAndPickReadonly<
  T,
  Props extends keyof T,
  ReadonlyProps extends keyof T = Props
> = PickAndReadonly<Partial<T>, Props, ReadonlyProps>;

export type OptionalValueFromKey<Keys, ValueType = unknown> = {
  [Key in Keys extends string ? Keys : never]?: ValueType;
};
