import { coerceNumber } from "./number";
import { coerceBoolean } from "./boolean";
import { coerceArray } from "./array";

export class Coerce {
  static number = coerceNumber;

  static boolean = coerceBoolean;

  static array = coerceArray;
}
