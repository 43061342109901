import { createActionGroup, props } from "@ngrx/store";
import { AccountRegisterRequestErrors } from "../../errors/account-register-errors";
import { RegistrationResponse } from "../../interfaces/user-registration";
import { LoginResponse } from "../../interfaces/user-login";
import { UserAuthorizationRequestErrors } from "../../errors/authorization-errors";

export interface RegisterSuccessActionProps {
  user: RegistrationResponse;
}

export interface RegisterFailedActionProps {
  error: AccountRegisterRequestErrors;
}

export interface LoginSuccessActionProps {
  userSession: LoginResponse;
  keepLoggedIn: boolean;
}

export interface LoginFailedActionProps {
  error: UserAuthorizationRequestErrors;
}

export const AuthApiActions = createActionGroup({
  source: "Auth/Api",
  events: {
    registerSuccess: props<RegisterSuccessActionProps>(),
    registerFailed: props<RegisterFailedActionProps>(),
    loginSuccess: props<LoginSuccessActionProps>(),
    loginFailed: props<LoginFailedActionProps>(),
  },
});
