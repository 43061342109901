import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpRequest,
  HttpStatusCode,
} from "@angular/common/http";
import { inject } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, switchMap } from "rxjs";
import { catchError, first, tap } from "rxjs/operators";
import { selectIsLoggedIn } from "../auth/state";
import { AuthSessionState } from "../auth/state/auth.state";

type HttpEventObservable = Observable<HttpEvent<unknown>>;

export function AuthorizationErrorInterceptor(
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
): HttpEventObservable {
  const router = inject(Router);

  const snackBar = inject(MatSnackBar);

  // select user session state
  const isLoggedIn$ = inject(Store<AuthSessionState>)
    .select(selectIsLoggedIn)
    .pipe(first());

  // only watch for error status b/w > 400 and < 500
  const hasRestrictiveStatusCode = (httpError: HttpErrorResponse) =>
    httpError.status > HttpStatusCode.BadRequest &&
    httpError.status < HttpStatusCode.ServiceUnavailable;

  const catchHttpErrorResponse = catchError((httpError: HttpErrorResponse): HttpEventObservable => {
    const isApiCall = !!request.url.match(/^(.*)?api/);
    const isPermissionsApiCall = !!request.url.match(/permissions$/);
    const isUnauthorizedFromApiCall = isApiCall && hasRestrictiveStatusCode(httpError);

    const tapOnApiError = tap((isLoggedIn) => {
      const shouldAutoLogout = isLoggedIn && isUnauthorizedFromApiCall;

      // if cannot auto logout then throw the error in http chain
      // or if permission api call error, fail silently
      // http services who invoked the call will get an error
      if (!shouldAutoLogout || isPermissionsApiCall) {
        throw httpError;
      }

      // auto logout if Unauthorized response returned from api
      return (
        // show alert
        snackBar
          .open("Session expired!", "Dismiss", {
            horizontalPosition: "center",
            verticalPosition: "top",
            duration: 5000,
          })
          .afterOpened()
          // navigate to log out route
          .subscribe(() => router.navigate(["/auth/logout"]))
      );
    });

    return isLoggedIn$.pipe(
      // take actions based on user session and api errors
      tapOnApiError,
      // map to http request ot let the other interceptors handle the request after taking actions on error
      switchMap(() => next(request))
    );
  });

  return next(request).pipe(catchHttpErrorResponse) as HttpEventObservable;
}
