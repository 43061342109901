import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ObjectUtil, ValidationUtil, VOID } from "@eqn/data-types";
import { UserAuthorizationClaim } from "@leadgen/models";
import { AppService, loadingSubject } from "@leadgen/ui";
import { Actions, concatLatestFrom, createEffect, ofType, ROOT_EFFECTS_INIT } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { concat, EMPTY, map, of } from "rxjs";
import { catchError, exhaustMap, tap } from "rxjs/operators";
import { AccountRegisterRequestErrors } from "../errors/account-register-errors";
import { LoginResponse } from "../interfaces/user-login";
import { RegistrationResponse } from "../interfaces/user-registration";
import { UserSessionStorage } from "../interfaces/user-session";
import { AuthApiService } from "../services/auth-api.service";
import { UserSessionStorageService } from "../services/user-session-storage";
import { AuthApiActions } from "./actions/auth-api.actions";
import { AuthPageActions } from "./actions/auth-page.actions";
import { AuthSessionActions } from "./actions/auth-session.actions";
import { selectKeepLoggedIn } from "./auth.selectors";
import { AuthSessionState } from "./auth.state";

@Injectable()
export class AuthEffects {
  private readonly _initUserPermissions$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      exhaustMap(() =>
        this._authApi.getUserPermissions().pipe(
          map((authorization) =>
            AuthSessionActions.setUserAuthorization({
              authorization: authorization as UserAuthorizationClaim,
            })
          ),
          catchError(() => EMPTY)
        )
      )
    )
  );

  private readonly _initUserStoredSession$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      map(() => {
        // get locally stored session
        const userSessionStoredLocally = this._userSessionStorage.get();

        // get session preference
        const keepLoggedIn = this._userSessionStorage.getSessionPreference()?.keepLoggedIn;

        const session = {
          userId: null,
          email: null,
          accessToken: null,
        } as unknown as UserSessionStorage;

        if (ValidationUtil.isNotEmpty(userSessionStoredLocally)) {
          Object.assign(session, userSessionStoredLocally);
        }

        return AuthSessionActions.setSession({ session, keepLoggedIn });
      })
    )
  );

  private readonly _register$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthPageActions.register),
      exhaustMap((action) =>
        this._authApi.register(action.user).pipe(
          map((registrationResult) =>
            AuthApiActions.registerSuccess({
              user: registrationResult as RegistrationResponse,
            })
          ),
          catchError((error: AccountRegisterRequestErrors) =>
            of(AuthApiActions.registerFailed({ error }))
          )
        )
      )
    )
  );

  private readonly _login$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthPageActions.login),
      exhaustMap((action) =>
        this._authApi.login(action.credentials).pipe(
          map((loginResult) =>
            AuthApiActions.loginSuccess({
              userSession: loginResult as LoginResponse,
              keepLoggedIn: action.keepLoggedIn,
            })
          ),
          catchError((error: AccountRegisterRequestErrors) =>
            of(AuthApiActions.loginFailed({ error }))
          )
        )
      )
    )
  );

  private readonly _storeUserSessionLocally$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AuthApiActions.loginSuccess),
        concatLatestFrom(() => this._store.select(selectKeepLoggedIn)),
        tap(([action, keepLoggedIn]) => {
          const userData = { ...action.userSession, userId: action.userSession.id };

          const session: UserSessionStorage = ObjectUtil.pick(userData, [
            "userId",
            "email",
            "accessToken",
            "firstName",
            "lastName",
          ]);

          this._userSessionStorage.set(session, { keepLoggedIn });
        })
      ),
    { dispatch: false }
  );

  private readonly _logout$ = createEffect(() =>
    concat(
      // this._actions$.pipe(ofType(AuthPageActions.logout)),
      this._app.logoutRequested$
    ).pipe(
      tap(() => {
        // this._userSessionStorage.clear();
        // this._router.navigate(["/auth/login"]).then();
        loadingSubject.next(true);
      }),
      // call logout api and if error occur fail silently
      exhaustMap(() => this._authApi.logout().pipe(catchError(() => of(VOID)))),
      map(() => {
        this._userSessionStorage.clear();
        this._router.navigate(["/auth/login"]).then();
        loadingSubject.next(false);
        return AuthSessionActions.removeUserSession();
      })
    )
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _authApi: AuthApiService,
    private readonly _userSessionStorage: UserSessionStorageService,
    private readonly _store: Store<AuthSessionState>,
    private readonly _router: Router,
    private readonly _app: AppService
  ) {}
}
