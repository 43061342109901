import { BooleanUtil } from "@eqn/data-types";
import {
  SetSessionActionProps,
  SetSessionUserActionProps,
  SetUserAuthorizationActionProps,
} from "../actions/auth-session.actions";
import { AuthSessionState, authInitialState } from "../auth.state";

const setUserSessionReducer = (state: AuthSessionState, action: SetSessionActionProps) => {
  const { session, keepLoggedIn } = action;

  state.keepLoggedIn = keepLoggedIn;
  state.isLoggedIn = BooleanUtil.isTruthy(session?.accessToken);
  state.accessToken = session?.accessToken;
};

const setUserAuthorizationReducer = (
  state: AuthSessionState,
  action: SetUserAuthorizationActionProps
) => {
  state.authorization = action.authorization;
};

const setSessionUserReducer = (state: AuthSessionState, action: SetSessionUserActionProps) => {
  state.user = action.user;
};

const removeUserSessionReducer = (state: AuthSessionState) => {
  Object.assign(state, authInitialState);
};

export default {
  setUserSessionReducer,
  setUserAuthorizationReducer,
  setSessionUserReducer,
  removeUserSessionReducer,
};
