import {
  LoginSuccessActionProps,
  RegisterFailedActionProps,
  RegisterSuccessActionProps,
} from "../actions/auth-api.actions";
import { authInitialState, AuthSessionState } from "../auth.state";
import { ObjectUtil } from "@eqn/data-types";

const registerSuccessReducer = (state: AuthSessionState, action: RegisterSuccessActionProps) => {
  const { user } = action;

  state.isLoggedIn = false;
  state.keepLoggedIn = false;
  state.error = null;
  state.accessToken = null;
  state.user = user;
};

const registerFailedReducer = (state: AuthSessionState, action: RegisterFailedActionProps) => {
  Object.assign(state, authInitialState);
  state.error = action.error;
};

const loginSuccessReducer = (state: AuthSessionState, action: LoginSuccessActionProps) => {
  const { userSession: session, keepLoggedIn } = action;

  state.isLoggedIn = true;
  state.keepLoggedIn = keepLoggedIn;
  state.error = null;
  state.accessToken = session.accessToken;
  state.user = ObjectUtil.omit(session, ["accessToken"]);

  // TODO: user authorization
};

const loginFailedReducer = (state: AuthSessionState, action: RegisterFailedActionProps) => {
  Object.assign(state, authInitialState);
  state.error = action.error;
};

export default {
  registerSuccessReducer,
  registerFailedReducer,
  loginSuccessReducer,
  loginFailedReducer,
};
