import { ValidationUtil } from "./validation-util";

export class BooleanUtil {
  static toBoolean<V>(value?: V) {
    if (!ValidationUtil.isNullOrUndefined(value)) {
      if (typeof value === "string" && value.match(/(false|0)/i)) {
        return false;
      }

      return Boolean(value);
    }

    return false;
  }

  static isBoolean<V>(value: V) {
    if (ValidationUtil.isNullOrUndefined(value)) {
      return false;
    }

    const booleanStr = value?.toString();

    return !!booleanStr?.match(/^(true|false)$/);
  }

  static equals<V1, V2>(value1: V1, value2: V2, equalOnUndefined = true) {
    const firstIsBool = BooleanUtil.isBoolean(value1);
    const secondIsBool = BooleanUtil.isBoolean(value2);

    if (equalOnUndefined && (!firstIsBool || !secondIsBool)) {
      return true;
    }

    return BooleanUtil.toBoolean(value1) === BooleanUtil.toBoolean(value2);
  }

  /**
   *  Returns `true` if given value is hard `true` value.
   */
  static isTruthy<V>(value?: V) {
    return BooleanUtil.toBoolean(value);
  }

  /**
   *  Returns `true` if given value is hard `false` value.
   */
  static isFalsy<V>(value?: V) {
    return !BooleanUtil.isTruthy(value);
  }
}
