import { map } from "rxjs/operators";
import {
  ApiErrorMessageFormat,
  GQLErrorResponse,
  GQLNetworkError,
  IGraphQLErrors,
  IGraphQLResult,
} from "@eqn/apollo-client";
import { RawRecord } from "@eqn/data-types";

export class GQLObservableOperator {
  static pickResponseData<T>(operationName?: string) {
    return map((gqlResponse: unknown) => {
      const gqlResponseData = (gqlResponse as IGraphQLResult<T>).data;

      const gqlResponseErrors = (gqlResponse as IGraphQLErrors).errors;

      if (gqlResponseErrors) {
        const error = gqlResponseErrors[0] as RawRecord;

        const serverResponse = error?.["extensions"]?.["response"];

        const isNetworkTypeError = !!serverResponse;

        if (isNetworkTypeError) {
          throw new GQLNetworkError(serverResponse as ApiErrorMessageFormat);
        }

        throw new GQLErrorResponse(
          (gqlResponse as IGraphQLErrors)?.errors as unknown as IGraphQLErrors[]
        );
      }

      let operationResult: T;

      if (operationName) {
        operationResult = gqlResponseData[operationName];
      } else {
        operationResult = Object.values(gqlResponseData)[0] as T;
      }

      return operationResult;
    });
  }
}
