export enum TaskState {
  STARTING = "STARTING",
  STARTED = "STARTED",
  PROGRESSING = "PROGRESSING",
  WAITING = "WAITING",
  LOADING = "LOADING",
  COMPLETED = "COMPLETED",
  ERROR = "ERROR",
  IDLE = "IDLE",
}
