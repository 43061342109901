import { Nullable } from "@eqn/data-types";
import { User, UserAuthorizationClaim } from "@leadgen/models";
import { AccountRegisterRequestErrors } from "../errors/account-register-errors";

export interface AuthSessionState {
  isLoggedIn: boolean;
  keepLoggedIn: boolean;
  accessToken: Nullable<string>;
  user: Nullable<User>;
  authorization: Nullable<UserAuthorizationClaim>;
  error: Nullable<AccountRegisterRequestErrors>;
}

export const authInitialState: Readonly<AuthSessionState> = {
  isLoggedIn: false,
  keepLoggedIn: false,
  accessToken: null,
  user: null,
  authorization: null,
  error: null,
};
