import { createFeature, createReducer } from "@ngrx/store";
import { verificationInitialState } from "./verification.state";
import { immerOn as on } from "ngrx-immer/store";
import emailVerificationPageReducers from "./reducers/verification-page.reducers";
import { EmailVerificationPageActions } from "./actions/verification-page.actions";

const VERIFICATION_FEATURE_NAME = "verification";

export const verificationFeature = createFeature({
  name: VERIFICATION_FEATURE_NAME,
  reducer: createReducer(
    verificationInitialState,
    on(
      EmailVerificationPageActions.setAfterRegistrationState,
      emailVerificationPageReducers.setAfterRegistrationReducer
    )
  ),
});

export const verificationSlice = {
  name: verificationFeature.name,
  reducer: verificationFeature.reducer,
} as const;
