import { ObjectLiteral } from "../types/object";
import { StringUtil } from "./string-util";
import { ValidationUtil } from "./validation-util";

export class ObjectUtil {
  static isDeepEqual(a: ObjectLiteral, b: ObjectLiteral) {
    const objKeys1 = Object.keys(a);
    const objKeys2 = Object.keys(b);

    if (objKeys1.length !== objKeys2.length) return false;

    for (const key of objKeys1) {
      const value1 = a[key];
      const value2 = b[key];

      const isObjects = ObjectUtil.isObject(value1) && ObjectUtil.isObject(value2);

      if (
        (isObjects && !ObjectUtil.isDeepEqual(value1, value2)) ||
        (!isObjects && value1 !== value2)
      ) {
        return false;
      }
    }

    return true;
  }

  static hasKey<T extends object>(o: T, key: keyof T) {
    // eslint-disable-next-line no-prototype-builtins
    return o?.hasOwnProperty(key);
  }

  static isPlainObject<T extends object>(value: T) {
    if (!value) {
      return false;
    }

    return !value?.constructor || value?.constructor === Object;
  }

  static pluck<T extends object, K extends keyof T>(target: T, propName: K) {
    return ObjectUtil.omit(target, [propName]);
  }

  static deepClone(data: object) {
    return JSON.parse(JSON.stringify(data));
  }

  static safeParse(stringifyObject: string, fallback: unknown = {}) {
    if (!ValidationUtil.isNotEmpty(stringifyObject)) {
      return fallback;
    }

    try {
      return JSON.parse(stringifyObject);
    } catch (e) {
      return fallback;
    }
  }

  /**
   * @description creates an object from same name keys nested within the target object
   * and trims the blank or empty objects from composed object,
   *
   * @param target
   *
   * @example
   *  target = { name: { name: value }, city: {}, country: null }
   *  flatten = { name: value }
   */
  static composeIntoSameKeys<T extends object>(target: T) {
    return Object.entries(target).reduce((acc, [, value]) => {
      if (ObjectUtil.isNotEmptyObject(value)) {
        Object.assign(acc, value);
      }

      return acc;
    }, {}) as T;
  }

  static omit<T extends object, K extends keyof T>(target: T, keys: readonly K[]) {
    const targetClone = ObjectUtil.deepClone(target);

    return Object.entries(targetClone).reduce((plucked, [key, value]) => {
      if (keys.includes(key as K)) {
        return plucked;
      }

      plucked[key] = value;

      return plucked;
    }, {} as ObjectLiteral) as Omit<T, (typeof keys)[number]>;
  }

  static pick<T extends object, K extends keyof T>(target: T, keys: readonly K[]) {
    const targetClone = ObjectUtil.deepClone(target);

    return Object.entries(targetClone).reduce((picked, [key, value]) => {
      if (!keys.includes(key as K)) {
        return picked;
      }

      picked[key] = value;

      return picked;
    }, {} as ObjectLiteral) as Pick<T, (typeof keys)[number]>;
  }

  static toCamelCaseKeys<T extends object>(target: T) {
    const targetClone = ObjectUtil.deepClone(target);

    return Object.entries(targetClone).reduce((camelCased, [key, value]) => {
      const camelCaseKey = StringUtil.camelCase(key);

      camelCased[camelCaseKey] = value;

      return camelCased;
    }, {} as ObjectLiteral) as T;
  }

  static toSnakeCaseKeys<T extends object>(target: T) {
    const targetClone = ObjectUtil.deepClone(target);

    return Object.entries(targetClone).reduce((snakeCased, [key, value]) => {
      const snakeCaseKey = StringUtil.snakeCase(key);

      snakeCased[snakeCaseKey] = value;

      return snakeCased;
    }, {} as ObjectLiteral) as T;
  }

  static isObject(value: unknown) {
    return ValidationUtil.isObject(value);
  }

  static isNotEmptyObject<T>(value: unknown): value is T {
    return ValidationUtil.isNotEmptyObject(value);
  }

  static toMap<T extends object>(target: T) {
    const targetMap = new Map();

    Object.entries(target).forEach(([k, v]) => targetMap.set(k, v));

    return targetMap;
  }

  static fromMap<T>(srcMap: Map<string, unknown> | unknown): T {
    const target = {} as ObjectLiteral;

    if (!(srcMap instanceof Map)) {
      return srcMap as T;
    }

    srcMap?.forEach((v, k) => {
      target[k] = v;
    });

    return target as T;
  }
}
