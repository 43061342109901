import { ApplicationConfig, importProvidersFrom } from "@angular/core";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideRouter, withComponentInputBinding } from "@angular/router";
import { provideApolloClientOptions } from "@eqn/apollo-client";
import { provideEffects } from "@ngrx/effects";
import { provideState, provideStore } from "@ngrx/store";
import { TablerIconsModule } from "angular-tabler-icons";
import * as TablerIcons from "angular-tabler-icons/icons";
import { ApolloModule } from "apollo-angular";
import { environment } from "../environments/environment";
import { AuthEffects, authSlice } from "./auth/state";
import routes from "./routes";
import { provideHttpClient, withInterceptors } from "@angular/common/http";
import { AuthorizationInterceptor } from "./interceptors/jwt.interceptor";
import { AuthorizationErrorInterceptor } from "./interceptors/error.interceptor";
import { VerificationEffects, verificationSlice } from "./verifications/state";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { provideToastr } from "ngx-toastr";
const MODULES_PROVIDERS = importProvidersFrom(
  TablerIconsModule.pick(TablerIcons),
  ApolloModule,
  MatSnackBarModule
);

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(routes, withComponentInputBinding()),
    provideHttpClient(withInterceptors([AuthorizationInterceptor, AuthorizationErrorInterceptor])),
    provideApolloClientOptions(environment.gqlEndpoint),
    provideStore(),
    provideState(authSlice),
    provideState(verificationSlice),
    provideEffects(AuthEffects, VerificationEffects),
    MODULES_PROVIDERS,
    environment?.providers || [],
    provideToastr(),
  ],
};
