import { Nullable } from "@eqn/data-types";
import { TaskState } from "@eqn/common";

export interface VerificationState {
  isRegistrationCompleted: Nullable<boolean>;
  networkStatus: TaskState;
}

export const verificationInitialState: Readonly<VerificationState> = {
  isRegistrationCompleted: null,
  networkStatus: TaskState.IDLE,
};
