import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { isPublicRoute } from "@eqn/angular/router";
import { Nullable, ValidationUtil } from "@eqn/data-types";
import { AccountStatus } from "@leadgen/models";
import { Store } from "@ngrx/store";
import { map } from "rxjs";
import { selectAuthState } from "../state";
import { AuthSessionState } from "../state/auth.state";
import { getAccessToken } from "../../interceptors/access-token";

export function authenticationRequiredGuard(next: ActivatedRouteSnapshot) {
  const router = inject(Router);

  const mapToNavigation = map((authState: Readonly<AuthSessionState>) => {
    // if public route then allow access
    if (isPublicRoute(next)) {
      return true;
    }

    const accessTokenRt = getAccessToken();
    const user = {
      accountStatus: "",
    };
    const authorization: any = [];
    let isLoggedIn = false;
    if (accessTokenRt) {
      isLoggedIn = true;
      user.accountStatus = "ACTIVE";
    }
    // const { isLoggedIn, user, authorization } = authState;
    const accountStatus = user?.accountStatus;

    let redirectPath: Nullable<string>;

    // if not logged in then redirect to login page
    if (!isLoggedIn || ValidationUtil.isNullOrUndefined(authorization)) {
      redirectPath = "/auth/login";
    }

    // if email is not verified redirect to email verification page
    if (accountStatus === AccountStatus.EMAIL_VERIFICATION_PENDING) {
      redirectPath = "/verification/email/pending";
    }
    // does not have valid subscription redirect to subscription purchase page
    else if (accountStatus === AccountStatus.SUBSCRIPTION_REQUIRED) {
      redirectPath = "/pricing";
    }

    // check if we need to redirect
    if (redirectPath) {
      router.navigate([redirectPath]).then();

      // restrict the next page access
      return false;
    }

    // allow the access to route
    return true;
  });

  return (
    inject(Store<AuthSessionState>)
      // get the user session
      .select(selectAuthState)
      .pipe(mapToNavigation)
  );
}
