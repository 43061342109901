import { EmailNotFoundError, UnknownError } from "@leadgen/errors";

export class EmailVerificationValidationError {
  constructor(public readonly message: string) {}
}

export type EmailVerificationRequestErrors =
  | EmailNotFoundError
  | EmailVerificationValidationError
  | UnknownError;
