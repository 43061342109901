import { AccountRole, AccountStatus } from "../account/account.constants";
import { IAppAbility } from "./app-ability";
import { RawRuleOf } from "@casl/ability";

export interface UserAuthorizationClaim {
  id: string; // iam_Edg194xcu
  userId: string;
  primaryUserId?: string;
  email: string;
  /**
   * Email address verification status.
   */
  isEmailVerified: boolean;
  /**
   * Type of user this account belongs to.
   *
   * @see {@link AccountType}
   */
  role: AccountRole;
  /**
   * User account status.
   * Account status states the user access to the system and resources.
   *
   * @see {@link AccountStatus}
   */
  accountStatus: AccountStatus;
  policyId?: string;
  permissions?: RawRuleOf<IAppAbility>[];
  hasSubscription?: boolean;
  allowedUsers?: number;
  downloadLimit?: string;
}
