import { HttpErrorResponse } from "@angular/common/http";
import { ObjectUtil } from "@eqn/data-types";

export class HttpErrorResult extends Error {
  constructor(public readonly errMessage: string, public readonly err: IHttpOriginalError | null) {
    super(errMessage);
  }
}

export type IHttpSuccessResponse<T> = {
  [key: string]: T;
};

export type IHttpOriginalError = Array<{
  message: string;
  extensions: {
    code: string;
    originalError: { message: string; error: string; statusCode: number };
  };
}>;
export interface IHttpResponse<T> {
  data: IHttpSuccessResponse<T>;
  errors: IHttpOriginalError;
}

export interface IResponseError<T> {
  statusCode?: number;
  message?: string | null;
  isError: boolean;
  data: T | null;
  error: IHttpOriginalError | null;
}

export class HttpUtil {
  static async getResponse<T>(
    responseData: Promise<IHttpResponse<T>>,
    successFieldPickKey = "data",
    sanitizeFnName = true
  ): Promise<T> {
    let responseResult: IHttpSuccessResponse<T> | IHttpResponse<T>;

    try {
      responseResult = (await responseData) as unknown as IHttpResponse<T>;
    } catch (err) {
      responseResult = err as unknown as IHttpSuccessResponse<T>;
    }

    const { isError, message, error, data } = HttpUtil.constructResponseWithErrAndSuccess<T>(
      responseResult,
      successFieldPickKey
    );

    if (isError) {
      throw new HttpErrorResult(message as string, error);
    } else {
      return sanitizeFnName
        ? (((Object.values(data || {}) || [])[0] || {}) as unknown as T)
        : (data as unknown as T);
    }
  }

  private static constructResponseWithErrAndSuccess<T>(
    response: IHttpSuccessResponse<T> | IHttpResponse<T>,
    successFieldPickKey: string
  ): IResponseError<T> {
    if (response instanceof Error || response instanceof HttpErrorResponse) {
      return {
        message:
          ((response as unknown as IHttpResponse<T>)?.errors || [])[0]?.message ||
          (response as unknown as Error)?.message,
        isError: true,
        data: null,
        error: (response as unknown as IHttpResponse<T>)?.errors || [],
      };
    } else {
      return {
        isError: false,
        error: null,
        data: successFieldPickKey
          ? (((response as unknown as IHttpSuccessResponse<T>) || {})[
              successFieldPickKey
            ] as T | null)
          : (response as T),
      };
    }
  }
}
