import { APOLLO_OPTIONS } from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import { InMemoryCache } from "@apollo/client/core";

export const provideApolloClientOptions = (gqlEndpoint: string) => {
  return {
    provide: APOLLO_OPTIONS,
    useFactory(httpLink: HttpLink) {
      return {
        cache: new InMemoryCache({
          resultCaching: false,
          addTypename: false,
        }),
        connectToDevTools: true,
        link: httpLink.create({ uri: gqlEndpoint }),
      };
    },
    deps: [HttpLink],
  };
};
