import { createFeature, createReducer, createSelector } from "@ngrx/store";
import { immerOn as on } from "ngrx-immer/store";
import { AuthApiActions } from "./actions/auth-api.actions";
import { AuthSessionActions } from "./actions/auth-session.actions";
import { authInitialState } from "./auth.state";
import authApiReducers from "./reducers/auth-api.reducers";
import authSessionReducers from "./reducers/auth-session.reducers";

const AUTH_FEATURE_NAME = "auth";

export const authFeature = createFeature({
  name: AUTH_FEATURE_NAME,
  reducer: createReducer(
    authInitialState,
    on(AuthApiActions.registerSuccess, authApiReducers.registerSuccessReducer),
    on(AuthApiActions.registerFailed, authApiReducers.registerFailedReducer),
    on(AuthApiActions.loginSuccess, authApiReducers.loginSuccessReducer),
    on(AuthApiActions.loginFailed, authApiReducers.loginFailedReducer),
    on(AuthSessionActions.setSession, authSessionReducers.setUserSessionReducer),
    on(AuthSessionActions.setUserAuthorization, authSessionReducers.setUserAuthorizationReducer),
    on(AuthSessionActions.setSessionUser, authSessionReducers.setSessionUserReducer),
    on(AuthSessionActions.removeUserSession, authSessionReducers.removeUserSessionReducer)
  ),
  extraSelectors: ({ selectUser, selectAuthorization }) => ({
    selectUserId: createSelector(selectUser, (user) => user?.id),
    selectPermissions: createSelector(selectAuthorization, (claim) => claim?.permissions),
  }),
});

export const authSlice = { name: authFeature.name, reducer: authFeature.reducer } as const;
