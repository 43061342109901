import { Injectable, inject } from "@angular/core";
import { ToastrService } from "ngx-toastr";
@Injectable({
  providedIn: "root",
})
export class ToasterPopupService {
  private readonly toasterService = inject(ToastrService);
  config = {
    timeOut: 10000,
    closeButton: true,
    positionClass: "toast-top-right",
    progressBar: true,
    tapToDismiss: true,
  };

  public success(title: string, message?: string) {
    this.toasterService.success(title, message, this.config);
  }
  public warning(title: string, message?: string) {
    this.toasterService.warning(title, message, this.config);
  }
  public error(title: string, message?: string) {
    this.toasterService.error(title, message, this.config);
  }
  public info(title: string, message?: string) {
    this.toasterService.info(title, message, this.config);
  }
}
