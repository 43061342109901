import { Coerce } from "@eqn/data-types";
import { UnauthorizedError, UnknownError } from "@leadgen/errors";

export class ResetPasswordValidationError {
  public readonly message: string;

  constructor(message: string | string[]) {
    this.message = Coerce.array(message)[0];
  }
}

export type ResetPasswordRequestErrors =
  | UnauthorizedError
  | ResetPasswordValidationError
  | UnknownError;

export type ResetPasswordTokenValidationRequestErrors = UnauthorizedError | UnknownError;
