export enum ThemeColorScheme {
  ORANGE = "orange_theme",
  BLUE = "blue_theme",
  AQUA = "aqua_theme",
  PURPLE = "purple_theme",
  GREEN = "green_theme",
  CYAN = "cyan_theme",
}

export interface AppSettings {
  dir: "ltr" | "rtl";
  theme: string;
  sidenavOpened: boolean;
  sidenavCollapsed: boolean;
  boxed: boolean;
  horizontal: boolean;
  activeTheme: ThemeColorScheme & string;
  language: string;
  cardBorder: boolean;
  navPos: "side" | "top";
  customizer: boolean;
  breadcrumb: boolean;
}

export const defaults: AppSettings = {
  dir: "ltr",
  theme: "light",
  sidenavOpened: false,
  sidenavCollapsed: false,
  boxed: false,
  horizontal: true,
  cardBorder: false,
  activeTheme: ThemeColorScheme.BLUE,
  language: "en-us",
  navPos: "side",
  customizer: false,
  breadcrumb: false,
};
