import { NgIf } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { loadingSubject } from "./progress-bar.subject";
import { MatProgressBarModule } from "@angular/material/progress-bar";
@Component({
  selector: "leadgen-app-progress-bar-loader",
  template: `
    <mat-progress-bar
      *ngIf="loadingState"
      class="progress-loading"
      mode="indeterminate"
    ></mat-progress-bar>
  `,
  styles: [
    `
      .progress-loading {
        position: fixed;
        z-index: 999;
      }
    `,
  ],
  standalone: true,
  imports: [NgIf, MatProgressBarModule],
})
export class ProgressBarComponent implements OnInit {
  public loadingState = false;

  ngOnInit() {
    loadingSubject.subscribe((elem: boolean) => {
      this.loadingState = elem;
    });
  }
}
