import { AbilityClass, MongoAbility, PureAbility } from "@casl/ability";
import { Action } from "./action.constants";
import { AppResource } from "./app-resource";

export type Subjects = AppResource;

export type AppAbilities = [Action, Subjects];

export type IAppAbility = MongoAbility<AppAbilities>;

export const AppAbility = PureAbility as AbilityClass<IAppAbility>;
