import { Component, Input } from "@angular/core";

@Component({
  selector: "leadgen-loading-spinner",
  standalone: true,
  template: ` <div class="loading-shade">
    <div class="d-flex flex-col justify-content-center align-items-center">
      <div class="loader"></div>
      <ng-content></ng-content>
    </div>
  </div>`,
  styleUrls: ["./spinner.component.scss"],
})
export class LoadingSpinnerComponent {
  @Input() isLoading = false;
}
