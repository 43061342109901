import { Routes } from "@angular/router";
import { authenticationRequiredGuard } from "./auth/guards/auth.guard";
import { VerificationApiService } from "./verifications/services/verification-api.service";

export default [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "/leads/search",
  },
  {
    path: "auth",
    loadChildren: () => import("./auth/routes"),
  },
  {
    path: "leads",
    canActivate: [authenticationRequiredGuard],
    loadChildren: () => import("./leads-management/routes"),
  },
  // {
  //   path: "email-warmup",
  //   canActivate: [authenticationRequiredGuard],
  //   loadChildren: () => import("./email-warmup/routes"),
  // },
  {
    path: "account",
    loadChildren: () => import("./account/routes"),
  },
  {
    path: "verification",
    providers: [VerificationApiService],
    loadChildren: () => import("./verifications/routes"),
  },
  // {
  //   path: "campaigns",
  //   canActivate: [authenticationRequiredGuard],
  //   loadChildren: () => import("./email-campaigns/routes"),
  // },
  // {
  //   path: "analytics",
  //   canActivate: [authenticationRequiredGuard],
  //   loadChildren: () => import("./reports-analytics/routes"),
  // },
  // {
  //   path: "unibox",
  //   canActivate: [authenticationRequiredGuard],
  //   loadChildren: () => import("./unified-inbox/routes"),
  // },
  {
    path: "pricing",
    loadChildren: () => import("./subscription/pricing/routes"),
  },
  {
    path: "not-found",
    loadComponent: () => import("./troubled/not-found/not-found.component"),
  },
  {
    path: "**",
    redirectTo: "not-found",
  },
] as Routes;
