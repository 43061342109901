import { AccountRole, AccountStatus } from "../account/account.constants";

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  isEmailVerified: boolean;
  phone?: string;
  country?: string;
  primaryUserId?: string;
  accountStatus: AccountStatus;
  role: AccountRole;
  createdAt: Date;
}
