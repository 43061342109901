import { NgIf } from "@angular/common";
import { Component, inject } from "@angular/core";
import { CoreService } from "@modernize";

@Component({
  selector: "leadgen-branding",
  standalone: true,
  imports: [NgIf],
  template: `
    <div class="branding">
      <a href="/" *ngIf="themeOptions.theme === 'light'">
        <img src="./assets/images/logos/dark-logo.svg" class="align-middle m-2" alt="logo" />
      </a>
      <a href="/" *ngIf="themeOptions.theme === 'dark'">
        <img src="./assets/images/logos/light-logo.svg" class="align-middle m-2" alt="logo" />
      </a>
    </div>
  `,
})
export class BrandingComponent {
  public readonly themeOptions = inject(CoreService).getOptions();

  constructor() {}
}
