import { Component, inject, InjectionToken, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { MatSidenavModule } from "@angular/material/sidenav";
import { RouterModule } from "@angular/router";

import { NgClass, NgComponentOutlet, NgIf } from "@angular/common";
import { TablerIconsModule } from "angular-tabler-icons";
import { MatButtonModule } from "@angular/material/button";
import { AppHorizontalHeaderComponent } from "./horizontal/header/header.component";
import { AppSidenavService, BaseAppLayoutComponent } from "./base-layout.component";
import { ObjectUtil } from "@eqn/data-types";
export const CHILD_LAYOUT_COMPONENT = new InjectionToken<FunctionConstructor>("NESTED_LAYOUT");

export const provideChildLayout = <C>(component: C) => ({
  provide: CHILD_LAYOUT_COMPONENT,
  useValue: component,
});

@Component({
  selector: "leadgen-app-layout",
  standalone: true,
  imports: [
    RouterModule,
    NgClass,
    NgComponentOutlet,
    NgIf,
    MatButtonModule,
    MatSidenavModule,
    TablerIconsModule,
    AppHorizontalHeaderComponent,
  ],
  templateUrl: "./layout.component.html",
  encapsulation: ViewEncapsulation.None,
  providers: [AppSidenavService],
})
export class AppLayoutComponent extends BaseAppLayoutComponent {
  public readonly childLayoutComponent = inject(CHILD_LAYOUT_COMPONENT, { optional: true });

  @Input()
  email: string;
  @Input()
  name: string;
  constructor() {
    super();
  }
}
