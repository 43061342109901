import { ActivatedRouteSnapshot, Data } from "@angular/router";
import { BooleanUtil, ObjectUtil } from "@eqn/data-types";

export interface PublicRouteData {
  isPublicRoute?: boolean;
}

export const markRoutePublic = (data?: Data): Data => {
  const mergedData = ObjectUtil.isNotEmptyObject<Data>(data) ? data : {};

  return Object.assign(mergedData, { isPublicRoute: true });
};

export const isPublicRoute = (route: ActivatedRouteSnapshot) =>
  BooleanUtil.isTruthy((route.data as PublicRouteData)?.isPublicRoute);
