import { ValidationUtil } from "./validation-util";

export class StringUtil {
  static toUpperCaseList(values: string[]) {
    return (values || []).map((v) => v.toUpperCase());
  }

  static toLowerCaseList(values: string[]) {
    return (values || []).map((v) => v.toLowerCase());
  }

  static isUpperCase(value: string): boolean {
    return value?.toString().toUpperCase() === value;
  }

  static isLowerCase(value: string): boolean {
    return value?.toString().toLowerCase() === value;
  }

  static snakeCase(str: string): string {
    return str
      .replace(/([A-Z])([A-Z])([a-z])/g, "$1_$2$3")
      .replace(/([a-z0-9])([A-Z])/g, "$1_$2")
      .toLowerCase();
  }

  static capitalize(str: string): string | undefined {
    if (ValidationUtil.isNullOrUndefined(str)) {
      return;
    }

    return str[0]?.toUpperCase() + str?.substring(1);
  }

  static camelCase(str: string, firstCapital = false): string {
    return str.replace(/^([A-Z])|[\s-_](\w)/g, (match, p1, p2, offset) => {
      if (firstCapital === true && offset === 0) return p1;
      if (p2) return p2.toUpperCase();
      return p1.toLowerCase();
    });
  }
}
