import { Component, EventEmitter, HostBinding, Input, OnChanges, Output } from "@angular/core";
import { NavItem } from "./nav-item";
import { Router } from "@angular/router";
import { NavService } from "@modernize";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { TablerIconsModule } from "angular-tabler-icons";
import { NgClass, NgFor, NgIf } from "@angular/common";
import { MatListModule } from "@angular/material/list";
import { MatIconModule } from "@angular/material/icon";

@Component({
  selector: "leadgen-nav-item",
  standalone: true,
  imports: [TablerIconsModule, MatListModule, MatIconModule, NgIf, NgClass, NgFor],
  templateUrl: "./nav-item.component.html",
  styleUrls: [],
  animations: [
    trigger("indicatorRotate", [
      state("collapsed", style({ transform: "rotate(0deg)" })),
      state("expanded", style({ transform: "rotate(180deg)" })),
      transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4,0.0,0.2,1)")),
    ]),
  ],
})
export class AppNavItemComponent implements OnChanges {
  @Output()
  public readonly toggleMobileLink = new EventEmitter<void>();
  @Output()
  public readonly notify: EventEmitter<boolean> = new EventEmitter<boolean>();

  public expanded = false;
  public disabled = false;
  public twoLines = false;

  @HostBinding("attr.aria-expanded")
  public ariaExpanded = this.expanded;

  @Input()
  public item: NavItem;

  @Input()
  public depth = 0;

  constructor(private readonly _navService: NavService, public readonly router: Router) {}

  ngOnChanges() {
    this._navService.currentUrl.subscribe((url: string) => {
      if (this.item.route && url) {
        this.expanded = url.indexOf(`/${this.item.route}`) === 0;
        this.ariaExpanded = this.expanded;
      }
    });
  }

  public onItemSelected(item: NavItem) {
    if (!item.children || !item.children.length) {
      this.router.navigate([item.route]).then();
    }
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }

    if (!this.expanded) {
      if (window.innerWidth < 1024) {
        this.notify.emit();
      }
    }
  }

  public onSubItemSelected(item: NavItem) {
    if (!item.children || !item.children.length) {
      if (this.expanded && window.innerWidth < 1024) {
        this.notify.emit();
      }
    }
  }
}
