import { ApolloQueryResult, MutationQueryReducer } from "@apollo/client/core";
import { ObjectUtil } from "@eqn/data-types";
import { Observable } from "rxjs";

export class ObservableUtil {
  // Sometimes required To Promised From Obs
  public static toPromised<T>(
    observable$: Observable<ApolloQueryResult<T> | MutationQueryReducer<T>> | any
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      observable$.subscribe({
        next: (data: ApolloQueryResult<T>) => {
          resolve(ObjectUtil.deepClone(data as unknown as object));
        },
        error: (err: any) => {
          reject(err);
        },
      });
    });
  }

  // Regular Obs Operation
}
