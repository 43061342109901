<mat-toolbar class="topbar horizontal-topbar">
  <div class="container max-vw-100">
    <div class="d-none d-sm-flex">
      <leadgen-branding></leadgen-branding>
    </div>

    <!-- Mobile Menu -->
    <button (click)="toggleMobileNav.emit()" class="d-block d-lg-none" mat-icon-button>
      <i-tabler class="icon-20 d-flex" name="menu-2"></i-tabler>
    </button>
    <!-- --------------------------------------------------------------- -->

    <div class="d-none d-lg-flex">
      <!--  Links -->
      <a [routerLink]="['/leads/search']" mat-button>Find Leads</a>
      <a [routerLink]="['/leads/saved']" mat-button>Saved Leads</a>
      <!-- <a [routerLink]="['/email-warmup']" mat-button>Send Emails</a> -->
    </div>

    <span class="flex-1-auto"></span>

    <!-- Mobile Menu -->
    <button
      (click)="toggleMobileFilterNav.emit()"
      class="d-flex d-lg-none justify-content-center"
      mat-icon-button
    >
      <i-tabler class="icon-20 d-flex" name="grid-dots"></i-tabler>
    </button>

    <!-- --------------------------------------------------------------- -->
    <!-- Notification Dropdown -->
    <!-- --------------------------------------------------------------- -->
    <!-- <button [matMenuTriggerFor]="notificationmenu" aria-label="Notifications" mat-icon-button>
      <i-tabler class="d-flex" matBadge="1" matBadgeColor="primary" name="bell"></i-tabler>
    </button>
    <mat-menu #notificationmenu="matMenu" class="topbar-dd cardWithShadow" xPosition="before">
      <div class="d-flex align-items-center p-x-32 p-y-16">
        <h6 class="f-s-16 f-w-600 m-0">Notifications</h6>
        <span class="m-l-auto">
          <span class="bg-primary text-white p-x-8 p-y-4 f-w-500 rounded f-s-12">5 new</span>
        </span>
      </div>
      <button *ngFor="let notification of notifications" class="p-x-32 p-y-16" mat-menu-item>
        <div class="d-flex align-items-center">
          <img [src]="notification.img" class="rounded-circle" width="48" />
          <div class="m-l-16">
            <h5 class="f-s-14 f-w-600 m-0 mat-subtitle-1">
              {{ notification.title }}
            </h5>
            <span>{{ notification.subtitle }}</span>
          </div>
        </div>
      </button>
      <div class="p-y-12 p-x-32">
        <button class="w-100" color="primary" mat-stroked-button>See all notifications</button>
      </div>
    </mat-menu> -->

    <!-- profile Dropdown -->
    <button [matMenuTriggerFor]="profilemenu" aria-label="Notifications" mat-icon-button>
      <!-- <img class="rounded-circle object-cover" src="/assets/images/profile/user-1.jpg" width="35" /> -->
      <i-tabler class="routeIcon d-flex" name="user-circle" matListItemIcon></i-tabler>
    </button>
    <mat-menu #profilemenu="matMenu" class="topbar-dd cardWithShadow" xPosition="before">
      <div class="p-x-32 p-y-16">
        <h6 class="f-s-16 f-w-600 m-0">User Profile</h6>

        <div class="d-flex align-items-center p-b-24 b-b-1 m-t-16">
          <!-- <img class="rounded-circle" src="/assets/images/profile/user-1.jpg" width="95" /> -->
          <div class="">
            <span class="d-flex align-items-center">
              <i-tabler class="icon-15 m-r-4" name="user-circle"></i-tabler>
              <h6 class="f-s-14 f-w-600 m-0">{{ name }}</h6>
            </span>
            <!-- <span class="f-s-14 d-block m-b-4">Designer</span> -->
            <span class="d-flex align-items-center">
              <i-tabler class="icon-15 m-r-4" name="mail"></i-tabler>
              {{ email }}
            </span>
          </div>
        </div>
      </div>

      <div class="p-x-32">
        <a
          *ngFor="let profile of profiledd"
          [routerLink]="[profile.link]"
          class="p-y-16 text-decoration-none d-block text-hover-primary"
        >
          <div class="d-flex align-items-center">
            <button class="text-primary bg-light-primary shadow-none rounded" mat-mini-fab>
              <img [src]="profile.img" width="20" />
            </button>

            <div class="m-l-16">
              <h5 class="f-s-14 f-w-600 m-0 textprimary mat-subtitle-1 hover-text">
                {{ profile.title }}
              </h5>
              <span class="mat-body-1">{{ profile.subtitle }}</span>
            </div>
          </div>
        </a>
      </div>

      <div class="p-y-12 p-x-32">
        <button (click)="logoutUser()" class="w-100" color="primary" mat-stroked-button>
          Logout
        </button>
      </div>
    </mat-menu>
  </div>
</mat-toolbar>
