import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { UserSessionStorage } from "../interfaces/user-session";
import { Nullable, ObjectUtil, ValidationUtil } from "@eqn/data-types";

export interface SessionPreference {
  keepLoggedIn: boolean;
}

@Injectable({
  providedIn: "root",
})
export class UserSessionStorageService {
  private readonly _storageName = `${environment.localStorageStoreName}`;

  private readonly _sessionStoreKey = `${this._storageName}:session`;

  private readonly _sessionPreferenceStoreKey = `${this._sessionStoreKey}:preference`;

  public setSessionPreference(sessionPreference: SessionPreference) {
    window.localStorage.setItem(this._sessionPreferenceStoreKey, JSON.stringify(sessionPreference));
  }

  public set(userSession: UserSessionStorage, sessionPreference?: SessionPreference) {
    if (ValidationUtil.isNotEmptyObject(sessionPreference)) {
      this.setSessionPreference(sessionPreference);
    }

    this._getStorage().setItem(this._sessionStoreKey, JSON.stringify(userSession));
  }

  public get(): Nullable<UserSessionStorage> {
    const session = this._getStorage().getItem(this._sessionStoreKey);

    return ObjectUtil.safeParse(session as string, null);
  }

  public clear() {
    this.setSessionPreference({ keepLoggedIn: false });
    this._getStorage().removeItem(this._sessionStoreKey);
    this._getStorage().removeItem(this._sessionStoreKey);
  }

  public getSessionPreference(): SessionPreference {
    const sessionPreference = window.localStorage.getItem(
      this._sessionPreferenceStoreKey
    ) as string;

    return ObjectUtil.safeParse(sessionPreference, { keepLoggedIn: false });
  }

  private _getStorage() {
    const sessionPreference = this.getSessionPreference();

    return sessionPreference.keepLoggedIn ? window.localStorage : window.sessionStorage;
  }
}
