<mat-sidenav-container
  [dir]="themeOptions.dir!"
  [ngClass]="sideNavClasses"
  autoFocus
  autosize
  class="mainWrapper"
>
  <!-- vertical sidebar -->
  <mat-sidenav
    #sendEmailsSidenav
    (closedStart)="onSidenavClosedStart()"
    (openedChange)="onSidenavOpenedChange($event)"
    [mode]="isOver ? 'over' : 'side'"
    [opened]="themeOptions.navPos === 'side' && themeOptions.sidenavOpened && !isOver"
    class="sidebarNav"
  >
    <div class="flex-layout">
      <ng-scrollbar class="position-relative" style="height: 100%">
        <mat-nav-list class="sidebar-list">
          <leadgen-nav-item *ngFor="let navItem of NAV_ITEMS" [item]="navItem" />
        </mat-nav-list>
      </ng-scrollbar>
    </div>
  </mat-sidenav>

  <!-- Outlet -->
  <mat-sidenav-content class="contentWrapper">
    <section
      [ngClass]="{
        maxWidth: themeOptions.boxed
      }"
      class="pageWrapper"
    >
      <router-outlet />
    </section>
  </mat-sidenav-content>
</mat-sidenav-container>
