import { verificationFeature } from "./verification.slice";
import { createSelector } from "@ngrx/store";
import { selectIsLoggedIn, selectUser } from "../../auth/state";

export const { selectIsRegistrationCompleted, selectNetworkStatus } = verificationFeature;

export const selectEmailVerificationPage = createSelector(
  selectIsLoggedIn,
  selectUser,
  selectIsRegistrationCompleted,
  (isLoggedIn, user, isAfterRegistration) => {
    if (isAfterRegistration) {
      return true;
    }

    if (!isLoggedIn) {
      return false;
    }

    return !(user && user?.isEmailVerified);
  }
);
