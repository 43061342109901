import { Component, EventEmitter, Input, OnInit, Output, inject } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { TablerIconsModule } from "angular-tabler-icons";
import { BrandingComponent } from "../../branding.component";
import { NgFor } from "@angular/common";

import { MatToolbarModule } from "@angular/material/toolbar";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";

import { MatIconModule } from "@angular/material/icon";
import { MatBadgeModule } from "@angular/material/badge";
import { AppService } from "../../../services/app.service";
import { ObjectUtil } from "@eqn/data-types";

interface notifications {
  id: number;
  img: string;
  title: string;
  subtitle: string;
}

interface profiledd {
  id: number;
  img: string;
  title: string;
  subtitle: string;
  link: string;
}

@Component({
  selector: "leadgen-horizontal-header",
  standalone: true,
  imports: [
    RouterModule,
    TablerIconsModule,
    MatToolbarModule,
    MatButtonModule,
    MatBadgeModule,
    MatIconModule,
    MatMenuModule,
    BrandingComponent,
    NgFor,
  ],
  templateUrl: "./header.component.html",
})
export class AppHorizontalHeaderComponent implements OnInit {
  router = inject(Router);

  @Input()
  public showToggle = true;

  @Input()
  public toggleChecked = false;

  @Output()
  public toggleMobileNav = new EventEmitter<void>();

  @Output()
  public toggleMobileFilterNav = new EventEmitter<void>();

  @Output()
  public toggleCollapsed = new EventEmitter<void>();

  @Input()
  public email: string;

  @Input()
  public name: string;

  @Input()
  public notifications: notifications[] = [
    // {
    //   id: 1,
    //   img: "/assets/images/profile/user-1.jpg",
    //   title: "Roman Joined the Team!",
    //   subtitle: "Congratulate him",
    // },
    // {
    //   id: 2,
    //   img: "/assets/images/profile/user-2.jpg",
    //   title: "New message received",
    //   subtitle: "Salma sent you new message",
    // },
    // {
    //   id: 3,
    //   img: "/assets/images/profile/user-3.jpg",
    //   title: "New Payment received",
    //   subtitle: "Check your earnings",
    // },
    // {
    //   id: 4,
    //   img: "/assets/images/profile/user-4.jpg",
    //   title: "Jolly completed tasks",
    //   subtitle: "Assign her new tasks",
    // },
    // {
    //   id: 5,
    //   img: "/assets/images/profile/user-5.jpg",
    //   title: "Roman Joined the Team!",
    //   subtitle: "Congratulate him",
    // },
  ];

  public readonly profiledd: profiledd[] = [
    {
      id: 1,
      img: "/assets/images/svgs/icon-account.svg",
      title: "My Profile",
      subtitle: "Account Settings",
      link: "/account/profile",
    },
    {
      id: 2,
      img: "/assets/images/svgs/icon-dd-invoice.svg",
      title: "Billing",
      subtitle: "Subscription Management",
      link: "/account/billing",
    },
  ];

  constructor(private readonly _app: AppService) {}

  ngOnInit() {
    const obj = ObjectUtil.safeParse(localStorage.getItem(window.location.host + ":session") || "");
    const name = obj.firstName + " " + obj.lastName;
    const email = obj.email;

    this.name = name;
    this.email = email;
  }

  public logoutUser() {
    // this.router.navigate(["/auth/login"]);
    // setTimeout(() => {
    //   localStorage.removeItem("emsk:dev:local:session:preference");
    //   localStorage.removeItem("emsk:dev:local:session");
    //   localStorage.removeItem("loglevel");
    // }, 1000);
    this._app.requestLogout();
  }
}
