import { createActionGroup, emptyProps, props } from "@ngrx/store";

export interface SetAfterRegistrationActionProps {
  isRegistrationCompleted: boolean;
}

export const EmailVerificationPageActions = createActionGroup({
  source: "EmailVerification/Page",
  events: {
    requestEmailVerification: emptyProps(),
    setAfterRegistrationState: props<SetAfterRegistrationActionProps>(),
  },
});
