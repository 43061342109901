import { Component, OnInit, inject } from "@angular/core";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
  RouterOutlet,
} from "@angular/router";
import { loadingEvent } from "./gql/loading";
import { NgIf } from "@angular/common";
import { ProgressBarComponent, ToasterPopupService, loadingSubject } from "@leadgen/ui";
import { map } from "rxjs";
@Component({
  selector: "leadgen-root",
  standalone: true,
  imports: [RouterOutlet, MatProgressBarModule, NgIf, ProgressBarComponent],
  template: `
    <leadgen-app-progress-bar-loader></leadgen-app-progress-bar-loader>
    <router-outlet />
  `,
})
export class AppComponent implements OnInit {
  private route = inject(Router);
  private toast = inject(ToasterPopupService);

  ngOnInit() {
    this.route.events
      .pipe(
        map((route) => {
          if (route instanceof NavigationStart) {
            loadingSubject.next(true);
          } else if (route instanceof NavigationEnd) {
            loadingSubject.next(false);
          }
        })
      )
      .subscribe();

    // debugger;
    // // this.toast.success("Yes", "Local message");

    // this.toast.success(
    //   "LeadGen app ",
    //   "Lorem ipsum dolor sit, amet consectetur adipisicing elit. "
    // );
  }
}
