import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { ApiErrorMessageFormat } from "./gql-response";
import { RawRecord } from "@eqn/data-types";

export interface IGraphQLErrors<E = IGraphQLValidationErrors[]> {
  extensions: IGraphExtensions;
  errors: E;
}

export interface IGraphQLValidationErrors {
  extensions: IGraphExtensions;
  messages: string;
}

export interface IGraphExtensions {
  type: string;
  name: string;
  status: HttpStatusCode;
  exception: {
    status: HttpStatusCode;
    response: unknown;
  };
}

export interface IGQLUnknownErrors {
  message: string;
  locations: string | string[];
  path: string;
}

export class GQLNetworkError {
  public readonly status: number;
  public readonly error: ApiErrorMessageFormat;

  constructor(public readonly response: ApiErrorMessageFormat) {
    this.status = response.statusCode;
    this.error = response;
  }
}

export interface GQLOperationError {
  gqlErrors?: IGraphQLErrors[];
  networkError?: HttpErrorResponse | GQLNetworkError;
  isNetworkError: boolean;
  isGQLError: boolean;
  error?: unknown;
}

export class GQLErrorResponse {
  networkError?: GQLNetworkError;
  error: RawRecord;
  status: HttpStatusCode;
  response: RawRecord;

  constructor(public readonly errors: IGraphQLErrors[]) {}
}
