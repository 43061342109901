import { ObjectUtil } from "@eqn/data-types";
import { environment } from "../../environments/environment";

export const getAccessToken = () => {
  const getSessionPreference = ObjectUtil.safeParse(
    localStorage.getItem(environment.localStorageStoreName + ":session:preference") as string
  );
  const session = (
    getSessionPreference.keepLoggedIn ? window.localStorage : window.sessionStorage
  ).getItem(environment.localStorageStoreName + ":session");

  if (session) {
    try {
      return ObjectUtil.safeParse(session as string)?.accessToken;
    } catch (err) {
      console.log(err);
    }
  }
};
