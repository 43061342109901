export enum Action {
  Create = "create",
  Read = "read",
  Update = "update",
  Delete = "delete",
  Export = "export",
  Edit = "edit",
  Manage = "manage",
  All = "all",
}
