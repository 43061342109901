import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { Credentials } from "../../interfaces/user-login";
import { UserRegistrationParams } from "../../interfaces/user-registration";

export interface RegisterActionProps {
  user: UserRegistrationParams;
}

export interface LoginActionProps {
  credentials: Credentials;
  keepLoggedIn: boolean;
}

export const AuthPageActions = createActionGroup({
  source: "Auth/Page",
  events: {
    register: props<RegisterActionProps>(),
    login: props<LoginActionProps>(),
    logout: emptyProps(),
  },
});
