export interface MinMaxRange {
  min: number;

  max: number;
}

export interface Between<T> {
  from: T;

  to: T;
}
