<!-- ============================================================== -->
<!-- Only router without any element -->
<!-- ============================================================== -->
<mat-sidenav-container
  [ngClass]="{
    cardBorder: themeOptions.cardBorder,
    orange_theme: themeOptions.activeTheme == THEME_COLOR.ORANGE,
    blue_theme: themeOptions.activeTheme == THEME_COLOR.BLUE,
    aqua_theme: themeOptions.activeTheme == THEME_COLOR.AQUA,
    purple_theme: themeOptions.activeTheme == THEME_COLOR.PURPLE,
    green_theme: themeOptions.activeTheme == THEME_COLOR.GREEN,
    cyan_theme: themeOptions.activeTheme == THEME_COLOR.CYAN
  }"
  [dir]="themeOptions.dir!"
>
  <router-outlet></router-outlet>
</mat-sidenav-container>
