import { verificationInitialState, VerificationState } from "../verification.state";
import { SetAfterRegistrationActionProps } from "../actions/verification-page.actions";

const setAfterRegistrationReducer = (
  state: VerificationState,
  action: SetAfterRegistrationActionProps
) => {
  Object.assign(state, verificationInitialState);
  state.isRegistrationCompleted = action.isRegistrationCompleted;
};

export default {
  setAfterRegistrationReducer,
};
