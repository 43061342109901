import { MutationResult } from "apollo-angular";

export type IGraphQLResult<T, K extends string = string> = Omit<MutationResult<T>, "data"> & {
  data: {
    [Key in K]: T;
  };
  networkStatus: number;
};

export interface ApiErrorMessageFormat<MsgType = string | string[]> {
  statusCode: number;
  message: MsgType;
  error: string;
}
