export interface IConstructor<T> {
  new (...args: any[]): T;
}

export interface IPrototype<T> {
  constructor: { name: string } & T;
}

export abstract class ConstructorFactory<F> {
  public prototype!: IPrototype<F>;
  public name!: string;
}

export function isInstanceOf(object: unknown, targetTypeConstructor: IConstructor<unknown>) {
  return object instanceof targetTypeConstructor;
}
