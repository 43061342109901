import { authFeature } from "./auth.slice";

export const {
  selectIsLoggedIn,
  selectUserId,
  selectPermissions,
  selectAccessToken,
  selectUser,
  selectError: selectAuthError,
  selectAuthState,
  selectKeepLoggedIn,
} = authFeature;
