import { HttpErrorResponse } from "@angular/common/http";
import { BooleanUtil, EMPTY_OBJECT, isInstanceOf, RawRecord } from "@eqn/data-types";
import {
  GQLErrorResponse,
  GQLNetworkError,
  GQLOperationError,
  IGQLUnknownErrors,
} from "../interfaces/gql-errors";
import { ApiErrorMessageFormat } from "../interfaces/gql-response";

function extractNetworkError(err: GQLErrorResponse): GQLNetworkError {
  if (err?.networkError) {
    return err.networkError;
  }

  if (!isHttpError(err)) {
    return err as unknown as GQLNetworkError;
  }

  const statusCode = err?.error?.["statusCode"] || err?.status;

  if (statusCode) {
    return {
      error: err?.response || err?.error,
      statusCode,
      status: statusCode,
    } as unknown as GQLNetworkError;
  }

  const extensions = err?.errors[0]?.extensions;
  const status = extensions?.exception?.status;

  if (status) {
    return {
      error: extensions.exception?.response,
      statusCode: status,
      ...extensions?.exception,
    } as unknown as GQLNetworkError;
  }

  return EMPTY_OBJECT as GQLNetworkError;
}

function isHttpError(err: GQLErrorResponse | HttpErrorResponse | GQLNetworkError): boolean {
  const isInstanceOfNetworkError =
    err instanceof HttpErrorResponse || err instanceof GQLNetworkError;

  const isGQLNetworkError = (err as GQLErrorResponse)?.networkError;

  if (isGQLNetworkError || isInstanceOfNetworkError) {
    return true;
  }

  if (!Array.isArray((err as GQLErrorResponse)?.errors)) {
    return false;
  }

  if ((err?.error as ApiErrorMessageFormat)?.["statusCode"] || err?.status) {
    return true;
  }

  // errors -> 0 - > extensions -> exception -> status
  const extensions = (err as GQLErrorResponse)?.errors[0]?.extensions;

  return BooleanUtil.isTruthy(extensions?.exception?.status);
}

export class GQLOperationMapper {
  static toOperationError(err: GQLErrorResponse): GQLOperationError {
    const isNetworkError = isHttpError(err);
    const networkError: HttpErrorResponse | GQLNetworkError = extractNetworkError(err);

    let isGQLError = false;
    const graphQLErrors = (err as RawRecord)?.["graphQLErrors"] || err?.errors;

    if ((graphQLErrors || isInstanceOf(err, GQLErrorResponse)) && !isNetworkError) {
      isGQLError = true;
    }

    // log each error, for internal use only
    if (isGQLError) {
      graphQLErrors.forEach((errorResponse: IGQLUnknownErrors) => {
        const { message, locations, path } = errorResponse;

        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      });
    }

    return {
      isGQLError,
      isNetworkError,
      gqlErrors: graphQLErrors,
      networkError,
    };
  }
}
