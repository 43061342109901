import { NumberOrString } from "../types/number";

export class CurrencyUtil {
  static getCurrencySymbol(currency: string) {
    const currencySymbol = new Intl.NumberFormat("en", {
      currency,
      style: "currency",
    })
      .formatToParts(0)
      .find((part) => part.type === "currency");
    return currencySymbol && currencySymbol.value;
  }

  static toCurrencyCode(currency: { currency: string; code: NumberOrString }) {
    if (currency && currency?.currency) {
      return currency.currency;
    } else if (currency && currency?.code) {
      return currency.code;
    } else if (currency && typeof currency === "string") {
      return currency;
    }

    return null;
  }
}
