<div *ngIf="item.navCap" class="nav-caption" mat-subheader>
  {{ item.navCap }}
</div>
<a
  (click)="onItemSelected(item)"
  *ngIf="!item.navCap && !item.external && !item.twoLines"
  [ngClass]="{
    'mat-toolbar mat-primary activeMenu': item.route ? router.isActive(item.route, false) : false,
    expanded: expanded,
    disabled: item.disabled
  }"
  class="menu-list-item"
  mat-list-item
>
  <i-tabler class="routeIcon" matListItemIcon name="{{ item.iconName }}"></i-tabler>
  <span class="hide-menu">{{ item.displayName || "" }}</span>
  <span *ngIf="item.children && item.children.length" class="arrow-icon" fxFlex>
    <span fxFlex></span>
    <mat-icon [@indicatorRotate]="expanded ? 'expanded' : 'collapsed'"> expand_more </mat-icon>
  </span>
  <span *ngIf="item.chip">
    <span class="{{ item.chipClass }} p-x-8 p-y-4 item-chip f-w-500 rounded-pill ">{{
      item.chipContent
    }}</span>
  </span>
</a>

<!-- external Link -->
<mat-list-item
  *ngIf="!item.navCap && item.external"
  class="menu-list-item"
  onClick="window.open('//google.com')"
  target="_blank"
>
  <i-tabler class="routeIcon" matListItemIcon name="{{ item.iconName }}"></i-tabler>
  <span class="hide-menu">{{ item.displayName || "" }}</span>
</mat-list-item>

<!-- children -->
<div *ngIf="expanded">
  <leadgen-nav-item
    (click)="onSubItemSelected(child)"
    *ngFor="let child of item.children || []"
    [depth]="depth + 1"
    [item]="child"
  >
  </leadgen-nav-item>
</div>
