/**
 * User type.
 */
export enum AccountRole {
  /**
   * Regular user.
   */
  PRIMARY_USER = "USER",

  /**
   * Regular user but with limited capabilities or secondary user invited by customer.
   */
  INVITED_USER = "INVITED_USER",

  /**
   * Admin user.
   */
  ADMIN = "ADMIN",
}

/**
 * Describes the user access to the system and resources.
 */
export enum AccountStatus {
  /**
   * Subscription is required to use the services.
   * When Subscription is canceled or not found on user account.
   */
  SUBSCRIPTION_REQUIRED = "SUBSCRIPTION_REQUIRED",

  /**
   * Account email is not yet verified.
   */
  EMAIL_VERIFICATION_PENDING = "EMAIL_VERIFICATION_PENDING",

  /**
   * Account is not yet active, waiting for approval.
   */
  APPROVAL_PENDING = "APPROVAL_PENDING",

  /**
   * Account is active and authorized to access resources.
   */
  ACTIVE = "ACTIVE",

  /**
   * Account is no longer in use.
   * Might not be accessed from a long period of time.
   */
  INACTIVE = "INACTIVE",

  /**
   * Account access is blocked.
   * Not authorized to perform any operation and resources access is blocked.
   */
  BLOCKED = "BLOCKED",

  /**
   * Account is softly deleted but can be reactivated again.
   */
  DELETED = "DELETED",
}
