import { Injectable } from "@angular/core";

import { HttpClient, HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { UnknownError, UserNotFoundError } from "@leadgen/errors";
import { Observable, throwError } from "rxjs";
import {
  EmailVerificationRequestErrors,
  EmailVerificationValidationError,
} from "../errors/email-verification-errors";
import { environment } from "../../../environments/environment";
import { EMPTY_REQ_BODY } from "@eqn/common";
import { catchError } from "rxjs/operators";

@Injectable()
export class VerificationApiService {
  private readonly _authApiEndpoint = `${environment.apiBaseUrl}/auth`;

  constructor(private readonly _http: HttpClient) {}

  public requestEmailVerification(): Observable<void | EmailVerificationRequestErrors> {
    const reqEmailVerificationErrorsHandler = (
      httpError: HttpErrorResponse
    ): Observable<EmailVerificationRequestErrors> => {
      const error = httpError?.error;
      const statusCode = httpError?.status;
      const message = error?.message || httpError?.message;

      let exception: EmailVerificationRequestErrors;

      switch (statusCode) {
        case HttpStatusCode.NotFound:
          exception = new UserNotFoundError();
          break;

        case HttpStatusCode.BadRequest:
          exception = new EmailVerificationValidationError(message);
          break;

        default:
          exception = new UnknownError(error);
      }

      return throwError(() => exception);
    };

    const url = `${this._authApiEndpoint}/email-verification`;

    return this._http
      .post<void>(url, EMPTY_REQ_BODY)
      .pipe(catchError(reqEmailVerificationErrorsHandler));
  }
}
