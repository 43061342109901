<mat-sidenav-container
  [dir]="themeOptions.dir!"
  [ngClass]="sideNavClasses"
  autoFocus
  autosize
  class="mainWrapper"
>
  <!-- Main Content -->
  <mat-sidenav-content #content class="contentWrapper">
    <!-- horizontal header -->
    <leadgen-horizontal-header
      (toggleMobileFilterNav)="filterNavRight?.toggle()"
      (toggleMobileNav)="toggleSidenav()"
      [name]="name"
      [email]="email"
    ></leadgen-horizontal-header>

    <!-- Dashboard content -->
    <main
      [ngClass]="{
        maxWidth: themeOptions.boxed
      }"
    >
      <ng-container *ngIf="childLayoutComponent; else noChildLayout">
        <ng-container [ngComponentOutlet]="$any(childLayoutComponent)"></ng-container>
      </ng-container>

      <ng-template #noChildLayout>
        <router-outlet />
      </ng-template>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>

<!-- Mobile Apps Sidebar -->
<mat-drawer #filterNavRight class="filter-sidebar" mode="over" position="end">
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="branding">
        <a *ngIf="themeOptions.theme === 'light'" href="/">
          <img alt="logo" class="align-middle m-2" src="./assets/images/logos/dark-logo.svg" />
        </a>
        <a *ngIf="themeOptions.theme === 'dark'" href="/">
          <img alt="logo" class="align-middle m-2" src="./assets/images/logos/light-logo.svg" />
        </a>
      </div>
      <button
        (click)="filterNavRight.toggle()"
        class="d-flex justify-content-center"
        mat-icon-button
      >
        <i-tabler class="icon-18 d-flex" name="x"></i-tabler>
      </button>
    </div>

    <div class="p-x-24">
      <a
        [routerLink]="['/leads/search']"
        class="d-flex align-items-center text-decoration-none mat-body-1 p-y-16 gap-8"
      >
        <span class="f-s-15 f-w-500 m-l-8">Find Leads</span>
      </a>
      <a
        [routerLink]="['/leads/saved']"
        class="d-flex align-items-center text-decoration-none mat-body-1 p-y-16"
      >
        <span class="f-s-15 f-w-500 m-l-8">Saved Leads</span>
      </a>
      <!-- <a
        [routerLink]="['/email-warmup']"
        class="d-flex align-items-center text-decoration-none mat-body-1 p-y-16 gap-8"
      >
        <span class="f-s-15 f-w-500 m-l-8">Send Emails</span>
      </a> -->
    </div>
  </div>
</mat-drawer>
